<template>
  <Backoffice :loginRequired="false">
    <div class="container">
      <div class="login__form-wrapper">
        <div class="login__column login__column--first">
          <div class="logo">
            <img
              src="@/assets/thomson-reuters-logo-white.png"
              alt="Dashboard | Thomson Reuters"
            />
          </div>
        </div>
        <div class="login__column login__column--second">
          <h1>Login</h1>
          <div class="logo hide-desktop">
            <img
              src="@/assets/thomson-reuters-logo.png"
              alt="Dashboard | Thomson Reuters"
            />
          </div>
          <form @submit.prevent="login" class="auth-form">
            <div class="field required" :class="{ error: $v.form.username.$error }">
              <div class="label">Usuário</div>
              <input
                type="text"
                tabindex="1"
                v-model.trim="$v.form.username.$model"
              />
            </div>
            <div class="field required" :class="{ error: $v.form.username.$error }">
              <div class="label">Senha</div>
              <input
                type="password"
                tabindex="2"
                v-model.trim="$v.form.password.$model"
              />
            </div>
            <div
              v-if="showErrorMessage"
              class="error__message ui red horizontal label"
            >
              <p>Usuário e/ou senha estão incorretos.</p>
            </div>
            <div
              v-if="showServerErrorMessage"
              class="error__message ui red horizontal label"
            >
              <p>Ops... Houve algum problema com o servidor.</p>
            </div>
            <button class="ui button" type="submit" tabindex="3">
              Entrar
            </button>
            <!-- <a class="link">
              <span @click="redirectToForgotPassword()">Esqueci minha senha</span>
            </a> -->
          </form>
        </div>
      </div>
    </div>
  </Backoffice>
</template>

<script>
import Backoffice from "../../../Template/Backoffice";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Login",
  components: {
    Backoffice,
  },
  props: {},
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      showErrorMessage: false,
      showServerErrorMessage: false,
      form: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      let vm = this;
      if (this.$v.$invalid) {
        vm.showErrorMessage = true;
        vm.loading = false;
      } else {
        vm.showErrorMessage = false;
        vm.loading = true;
        vm.$store.dispatch("Register/LOGIN", vm.form).then((data) => {
          let token = this.$jwt.decode(localStorage.token);
          if (data.authenticated) {
            vm.$router.push({ name: "backoffice" });
        } else {
            vm.showErrorMessage = true;
        }
          vm.loading = false;
        }).catch((error) => {
          vm.showServerErrorMessage = true;
          vm.loading = false;
        })
      }
    },
    redirectToForgotPassword() {
      this.$router.push({ name: "forgot" });
    },
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/globals/colors';
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: $color-gray-light;
}

.login__form-wrapper {
  display: flex;
  background-color: $color-white;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0,0,0,.1);
  max-width: 610px;
  width: 80%;
  overflow: hidden;
}

.login__column {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 50%;
  &--first {
    background-color: $color-secondary;
    padding-left: 50px;
    padding-right: 50px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('~@/assets/bg-thomson-reuters-office.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      mix-blend-mode: multiply;
      z-index: 0;
    }
  }
}

.logo {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
  img {
    width: 200px;
  }
}

.auth-form {
  display: flex;
  flex-direction: column;

  .field {
    width: 268px;
    margin-bottom: 30px;
    border-bottom: 1px solid #595959;
    box-sizing: border-box;
    .label {
      color: #595959;
      opacity: 0.8;
      font-size: 0.9rem;
      margin-top: 10px;
    }
    input {
      background-color: transparent;
      color: #595959;
      width: 100%;
      height: 30px;
      margin: 5px 0;
      font-size: 1rem;
      line-height: 30px;
      border: none;
      outline: none;
    }
  }
  button {
    width: 100%;
    border: none;
    margin: 10px 0 30px;
    border-radius: 8px;
    background-color: #595959;
    color: #fff;
    font-family: "Barlow";
    font-size: 0.9rem;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    transition: 400ms;
    &:hover,
    &:focus {
      background-color: #595959;
      color: #fff;
      opacity: 0.8;
    }
  }
  .link {
    display: flex;
    color: $color-primary;
    opacity: 0.6;
    justify-content: center;
    text-decoration: none;
    font-size: .8rem;
    font-weight: light;
    transition: 400ms;
    &:hover {
      opacity: 1;
    }
    span {
      cursor: pointer;
    }
  }
}
.error__message.ui.horizontal.label {
  width: 100%;
  margin: 0;
  font-weight: lighter;
}

@media screen and (max-width: 768px) {
  .login__form-wrapper {
    max-width: 80%;
  }
  .login__column {
    width: 100%;
    &--first {
      display: none;
    }
  }
}
</style>

